<template>
  <div class="main-container">
    <popup2/>
   

    <main-banner
    v-if="isNaN(MAIN_BANNER_MOBILE) * isNaN(MAIN_BANNER_WEB)"
    :MAIN_BANNER_WEB="MAIN_BANNER_WEB" 
    :MAIN_BANNER_MOBILE="MAIN_BANNER_MOBILE"/>
    <overlay-banner/>
    <sub-banner
    v-if="isNaN(SUB_BANNER_WEB) * isNaN(SUB_BANNER_MOBILE)"/>
    <class-container
    v-if="isNaN(CLASS_BANNER_CERTIFICATE) * isNaN(CLASS_BANNER_SPECIAL)"
    :CLASS_BANNER_CERTIFICATE="CLASS_BANNER_CERTIFICATE"
    :CLASS_BANNER_SPECIAL="CLASS_BANNER_SPECIAL"/>
    <table-container
    v-if="isNaN(CLASS_LIST)"
    :CLASS_LIST="CLASS_LIST"/>
    <board-container
    v-if="isNaN(NOTICE_LIST)"
    :NOTICE_LIST="NOTICE_LIST" />
    <Footer/>
  </div>
</template>

<script>
import MainBanner from '../components/banner/MainBanner'
import OverlayBanner from '../components/banner/OverlayBanner'
import SubBanner from '../components/banner/SubBanner'

import ClassContainer from '../components/container/ClassContainer'
import TableContainer from '../components/container/TableContainer'
import BoardContainer from '../components/container/BoardContainer'

import Footer from '../components/common/Footer'

import Popup2 from '../components/common/Popup2'
import { mapState, mapActions } from 'vuex';

export default {
  components : {
    // Banner components
    MainBanner,
    OverlayBanner,
    SubBanner,

    // Container components
    ClassContainer,
    TableContainer,
    BoardContainer,

    // Common components
    Footer,
    Popup2
  },

  methods : {
    ...mapActions([
      'FETCH_MAIN'
    ])
  },

  created() {
    this.FETCH_MAIN()
  },
 
  computed : {
    ...mapState([
      'MAIN_BANNER_WEB',
      'MAIN_BANNER_MOBILE',
      'SUB_BANNER',
      'CLASS_BANNER_SPECIAL',
      'CLASS_BANNER_CERTIFICATE',
      'CLASS_LIST',
      'BOARD_LIST',
    ])
  }

}
</script>

<style>

</style>
